import { IProfileResponse } from "@finbackoffice/clientbff-client";
import { AccountConfigType } from "@finbackoffice/fe-core";
import { ConfigContext } from "@finbackoffice/site-core";
import { useContext, useMemo } from "react";

export const useAccountConfig = (fields?: string[]) => {
    const { accountConfig } = useContext(ConfigContext);
    const obj = useMemo(() => {
        const result: Partial<Record<keyof IProfileResponse, AccountConfigType>> = {};

        accountConfig?.forEach((item) => {
            if (fields ? fields.includes(item.name) : true) {
                if (item.enable) {
                    result[item.name as keyof IProfileResponse] = item;
                }
            }
        });

        return result;
    }, [accountConfig, fields]);

    return obj;
};
