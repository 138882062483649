import dynamic from "next/dynamic";
import { FC, createContext, useCallback, useContext, useMemo } from "react";
import { IProfileResponse } from "@finbackoffice/clientbff-client";
import { SignupInputFieldName, TranslationScopes } from "@finbackoffice/enums";
import { ISiteConfigLayouts, ProfileLayoutEnum, SignupConfigType } from "@finbackoffice/fe-core";
import { UserAccountContext, useSiteConfig } from "@finbackoffice/site-core";
import { useLoadI18nextResource, useSignupConfig } from "hooks";
import PreloadComponent from "components/base/preload-component/PreloadComponent";

const Compact = dynamic(() => import("./layouts/compact/Compact"), {
    loading: () => <PreloadComponent style={{ height: 353, margin: 0, border: 0 }} />,
    ssr: false,
});

const Default = dynamic(() => import("./layouts/default/Default"), {
    loading: () => <PreloadComponent style={{ margin: 0, border: 0 }} />,
    ssr: false,
});

type IProps = {
    close?: () => void;
};

type IAccountContext = {
    email?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    userId?: number;
    isUserVerified: boolean;
    isAgent?: boolean;
    signupConfigObj: Partial<Record<keyof IProfileResponse | "password", SignupConfigType>>;
    onSignoutClickHandler: () => Promise<void>;
};

export const AccountContext = createContext<IAccountContext>(null as any);

const Account: FC<IProps> = ({ close }) => {
    const {
        logout,
        userEmail,
        username,
        userId,
        isUserVerified,
        userIsAgent,
        firstName,
        lastName,
    } = useContext(UserAccountContext);
    const { signupConfigObj } = useSignupConfig([SignupInputFieldName.Username]);
    const isTranslationsReady = useLoadI18nextResource(TranslationScopes.UserAccount);
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");

    const onSignoutClickHandler = useCallback(async () => {
        await logout();
        close?.();
    }, [close, logout]);

    const renderLayout = useMemo(() => {
        switch (siteLayoutsConfig.profile.type) {
            case ProfileLayoutEnum.Compact:
                return <Compact />;
            default:
                return <Default />;
        }
    }, [siteLayoutsConfig.profile.type]);

    const value = useMemo(
        () => ({
            userId,
            username,
            userEmail,
            firstName,
            lastName,
            isUserVerified,
            isAgent: userIsAgent,
            signupConfigObj,
            onSignoutClickHandler,
        }),
        [
            userEmail,
            firstName,
            lastName,
            userIsAgent,
            isUserVerified,
            signupConfigObj,
            userId,
            username,
            onSignoutClickHandler,
        ],
    );

    if (!isTranslationsReady) {
        return;
    }

    return <AccountContext.Provider value={value}>{renderLayout}</AccountContext.Provider>;
};

export default Account;
